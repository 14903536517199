<template>
  <div>
    <b-alert
      v-if="formErrors.non_field_errors !== undefined"
      variant="danger"
      show
    >
      {{ formErrors.non_field_errors[0] }}
    </b-alert>

    <b-form @submit.prevent="submitForm">
      <b-form-group
        label="First name / Nombre"
        label-for="first_name"
      >
        <b-form-input
          id="first_name"
          v-model="formFields.first_name"
          required
          :state="formErrors.first_name === undefined ? null : false"
          :aria-describedby="formErrors.first_name === undefined ? false : 'first_name-feedback'"
        />
        <b-form-invalid-feedback
          v-if="formErrors.first_name !== undefined"
          id="first_name-feedback"
        >
          {{ formErrors.first_name[0] }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Last name / Apellidos"
        label-for="last_name"
      >
        <b-form-input
          id="last_name"
          v-model="formFields.last_name"
          required
          :state="formErrors.last_name === undefined ? null : false"
          :aria-describedby="formErrors.last_name === undefined ? false : 'last_name-feedback'"
        />
        <b-form-invalid-feedback
          v-if="formErrors.last_name !== undefined"
          id="last_name-feedback"
        >
          {{ formErrors.last_name[0] }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Email address / Dirección de correo electrónico"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="formFields.email"
          type="email"
          required
          :state="formErrors.email === undefined ? null : false"
          :aria-describedby="formErrors.email === undefined ? false : 'email-feedback'"
        />
        <b-form-invalid-feedback
          v-if="formErrors.email !== undefined"
          id="email-feedback"
        >
          {{ formErrors.email[0] }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Password / Contraseña"
        label-for="password1"
      >
        <b-form-input
          id="password1"
          v-model="formFields.password1"
          type="password"
          required
          :state="formErrors.password1 === undefined ? null : false"
          :aria-describedby="'password1-help' + (formErrors.password1 === undefined ? '' : ' password1-feedback')"
        />
        <b-form-invalid-feedback
          v-if="formErrors.password1 !== undefined"
          id="password1-feedback"
        >
          {{ formErrors.password1[0] }}
        </b-form-invalid-feedback>
        <b-form-text id="password1-help">
          <ul>
            <li>Your password can't be too similar to your other personal information.</li>
            <li>Your password must contain at least 8 characters.</li>
            <li>Your password can't be a commonly used password.</li>
            <li>Your password can't be entirely numeric.</li>
          </ul>
        </b-form-text>
      </b-form-group>

      <b-form-group
        label="Password confirmation / Contraseña (de nuevo)"
        label-for="password2"
      >
        <b-form-input
          id="password2"
          v-model="formFields.password2"
          type="password"
          required
          :state="formErrors.password2 === undefined ? null : false"
          :aria-describedby="formErrors.password2 === undefined ? false : 'password2-feedback'"
        />
        <b-form-invalid-feedback
          v-if="formErrors.password2 !== undefined"
          id="password2-feedback"
        >
          {{ formErrors.password2[0] }}
        </b-form-invalid-feedback>
      </b-form-group>

      <transition-slide mode="out-in">
        <p v-if="programs.status == 'loading'">
          Loading programs
          <spinner color="var(--primary)" />
        </p>
        <div v-else>
          <template v-if="programs.status == 'loaded'">
            <b-form-group
              label="Select a Program / Seleccione un Programa"
              class="form-group-program"
            >
              <b-form-radio-group
                v-model="formFields.program"
                name="program"
                :state="formErrors.program === undefined ? null : false"
                stacked
              >
                <program-radio-card
                  v-for="program of programs.results"
                  :key="`program${program.id}`"
                  :program="program"
                  :active="formFields.program[0] === String(program.id)"
                />
              </b-form-radio-group>
            </b-form-group>
          </template>
          <p
            v-else
            class="alert alert-danger"
            role="alert"
          >
            Unable to load the list of programs. Please check your connection
            and
            <a
              href="javascript:void(0);"
              class="alert-link"
              @click="loadPrograms"
            >try again</a>.
          </p>
        </div>
      </transition-slide>

      <transition-slide mode="out-in">
        <div v-show="formFields.program">
          <transition-slide mode="out-in">
            <b-form-group
              v-if="formFields.program && priceData[formFields.program].discounted.amount === null"
              label="Discount code / Código de descuento"
              label-for="discount_code"
              class="discount_code-form-group"
            >
              <div>
                <b-form-input
                  id="discount_code"
                  v-model="formFields.discount_code"
                  :state="formErrors.discount_code === undefined ? null : false"
                  :aria-describedby="formErrors.discount_code === undefined ? false : 'discount_code-feedback'"
                  @keydown.prevent.enter="applyDiscount"
                />
                <b-form-invalid-feedback
                  v-if="formErrors.discount_code !== undefined"
                  id="discount_code-feedback"
                >
                  {{ formErrors.discount_code[0] }}
                </b-form-invalid-feedback>
              </div>
              <b-button
                v-if="formFields.program"
                variant="primary"
                size="sm"
                :disabled="priceData[formFields.program].discounted.status == 'loading'"
                @click="applyDiscount"
              >
                <template v-if="priceData[formFields.program].discounted.status == 'loading'">
                  Applying
                  <spinner />
                </template>
                <template v-else>
                  Apply
                </template>
              </b-button>
            </b-form-group>
          </transition-slide>

          <p v-if="stripeScriptStatus == 'loading'">
            Loading payment module
            <spinner color="var(--primary)" />
          </p>
          <div v-else-if="stripeScriptStatus == 'loaded'">
            <p
              v-if="formFields.program"
              class="total-cost mb-2"
            >
              Due:

              <template v-if="priceData[formFields.program].discounted.amount === null">
                {{ formatPrice(priceData[formFields.program].regular) }}
              </template>
              <template v-else>
                <s>{{ formatPrice(priceData[formFields.program].regular) }}</s>
                <span>{{ formatPrice(priceData[formFields.program].discounted.amount) }}</span>

                <b-button
                  type="submit"
                  variant="link"
                  size="sm"
                  :disabled="formSubmitting"
                  @click="removeDiscount"
                >
                  Remove discount
                </b-button>
              </template>

              <br>

              <small v-if="priceData[formFields.program].type === 'two_installments'">
                You will be billed the first installment today, and the second
                installment 1 month from now.
              </small>
              <small v-else-if="subscriptionSelected">
                You will be billed on monthly on this same day.
              </small>
            </p>


            <div v-show="formFields.program && (priceData[formFields.program].discounted.status != 'loaded' || priceData[formFields.program].discounted.amount > 0)">
              <div id="card-element" />
              <div
                id="card-errors"
                class="alert-danger"
                role="alert"
              />
            </div>
          </div>
          <p
            v-else-if="stripeScriptStatus == 'error'"
            class="alert alert-danger"
            role="alert"
          >
            We are experiencing issues connecting to our payments provider.
            Please check your connection and
            <a
              href="javascript:void(0);"
              class="alert-link"
              @click="loadStripeScript"
            >try again</a>.
          </p>
        </div>
      </transition-slide>

      <b-form-group>
        <b-form-checkbox
          v-model="formFields.agree"
          name="agree"
          class="mt-3"
          required
        >
          I agree to abide by the terms of use
          / Acepto cumplir con los términos de uso
        </b-form-checkbox>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
        :disabled="stripeScriptStatus != 'loaded' || formFields.program == '' || formSubmitting"
      >
        <template v-if="formSubmitting">
          Enrolling
          <spinner />
        </template>
        <template v-else>
          Enroll
        </template>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import TransitionSlide from '@/components/TransitionSlide.vue';
import ProgramRadioCard from '@/components/programs/ProgramRadioCard.vue';
import Stripe from '@/mixins/Stripe.vue';

export default {
  name: 'Enroll',
  components: {
    Spinner,
    TransitionSlide,
    ProgramRadioCard,
  },
  mixins: [Stripe],
  data() {
    return {
      formSubmitting: false,
      formErrors: {},
      formFields: {
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        password2: '',
        program: '',
        discount_code: '',
        agree: false,
      },
      stripeScriptStatus: 'loading',
      stripe: null,
      card: null,
      priceData: {},
      programs: { status: 'loading' },
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    subscriptionSelected() {
      try {
        return this.priceData[this.formFields.program].type === 'monthly_subscription';
      } catch {
        return false;
      }
    },
    twoInstallmentsSelected() {
      try {
        return this.priceData[this.formFields.program].type === 'two_installments';
      } catch {
        return false;
      }
    },
  },
  metaInfo: {
    title: 'Enroll',
  },
  created() {
    if (this.userData === null) {
      this.loadPrograms();
    }
  },
  methods: {
    async applyDiscount() {
      if (this.formFields.discount_code.trim() !== '') {
        delete this.formErrors.discount_code;

        this.priceData[this.formFields.program].discounted.status = 'loading';

        const postData = {
          program: this.formFields.program,
          discount_code: this.formFields.discount_code.trim(),
        };

        let response;

        try {
          response = await fetch(
            `${process.env.VUE_APP_API_URL}users/enrollment_prices/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            },
          );
        } catch (e) {} // eslint-disable-line no-empty

        if (response && [200, 400].includes(response.status)) {
          const responseJson = await response.json();

          if (response.status === 200) {
            this.priceData[this.formFields.program] = {
              regular: responseJson.regular,
              discounted: {
                status: 'loaded',
                amount: responseJson.discounted,
                code_used: postData.discount_code,
              },
              type: this.priceData[this.formFields.program].type,
            };

            this.formFields.discount_code = '';
          } else {
            this.$set(this.formErrors, 'discount_code', [responseJson]);
            this.priceData[postData.program].discounted.status = 'error';
          }
        } else {
          this.$set(this.formErrors, 'discount_code', [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ]);
          this.priceData[postData.program].discounted.status = 'error';
        }
      } else {
        this.$set(this.formErrors, 'discount_code', ['This field may not be blank.']);
      }
    },
    formatPrice(price) {
      return `$${price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')}`;
    },
    async loadPrograms() {
      this.programs = { status: 'loading' };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/enrollment_prices/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && response.status === 200) {
        const responseJson = await response.json();

        responseJson.forEach((program) => {
          program.payment_methods.forEach((pm) => {
            this.$set(
              this.priceData,
              `${program.id}-${pm.id}`,
              {
                total: pm.total,
                regular: pm.price,
                type: pm.type,
                discounted: {
                  status: 'idle',
                  amount: null,
                },
              },
            );
          });
        });

        this.programs = {
          status: 'loaded',
          results: responseJson,
        };
      } else {
        this.programs = { status: 'error' };
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      if (
        this.priceData[this.formFields.program].discounted.status !== 'loaded'
        || this.priceData[this.formFields.program].discounted.amount > 0
      ) {
        // SUBSCRIPTION ONBOARDING
        if (this.subscriptionSelected || this.twoInstallmentsSelected) {
          const billingEmail = this.formFields.email;

          let customerResponse;

          try {
            // CREATE CUSTOMER
            customerResponse = await fetch(
              `${process.env.VUE_APP_API_URL}users/create_customer/`,
              {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: billingEmail,
                }),
              },
            );
          } catch (e) {} // eslint-disable-line no-empty

          if (customerResponse && [201, 403].includes(customerResponse.status)) {
            const responseJson = await customerResponse.json();

            if (customerResponse.status === 201) {
              // CREATE PAYMENT METHOD
              this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card,
              }).then((result) => {
                if (result.error) {
                  const cardErrors = document.getElementById('card-errors');
                  cardErrors.classList.add('alert');
                  cardErrors.textContent = result.error.message;
                  this.formSubmitting = false;
                } else {
                  this.submitFormData(responseJson.customer.id, result.paymentMethod.id);
                }
              });
            } else {
              this.formErrors = responseJson;
              this.formSubmitting = false;
            }
          } else {
            this.formErrors = {
              non_field_errors: [
                'Unable to communicate with the server. Please check your '
                + 'connection and try again.',
              ],
            };
            this.formSubmitting = false;
          }
        // PAY IN FULL ON BOARDING
        } else {
          this.stripe.createToken(this.card).then((result) => {
            if (result.error) {
              const cardErrors = document.getElementById('card-errors');
              cardErrors.classList.add('alert');
              cardErrors.textContent = result.error.message;
              this.formSubmitting = false;
            } else {
              this.submitFormData('', result.token.id);
            }
          });
        }
      } else {
        this.submitFormData('', '');
      }
    },
    removeDiscount() {
      this.priceData[this.formFields.program].discounted = {
        status: 'idle',
        amount: null,
      };
    },
    async submitFormData(customerId, token) {
      const postData = JSON.parse(JSON.stringify(this.formFields));
      delete postData.discount_code;
      postData.stripe_customer_id = customerId;
      postData.token = token;
      [postData.program, postData.payment_method] = postData.program.split('-');

      let finalTotal = this.priceData[this.formFields.program].total;

      if (this.priceData[this.formFields.program].discounted.code_used !== undefined) {
        postData.discount_code = this.priceData[this.formFields.program].discounted.code_used;
        finalTotal = this.priceData[this.formFields.program].discounted.amount;
      } else {
        postData.discount_code = '';
      }

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}users/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [201, 400].includes(response.status)) {
        const responseJson = await response.json();

        if (response.status === 201) {
          // Record conversion
          window.tap(
            'conversion',
            `USERID${responseJson.id}`,
            finalTotal,
            { coupons: 'discount_code' in postData ? postData.discount_code : null },
          );

          this.$store.commit('userData', responseJson);
          let versionType = 'english';
          const programTitle = this.programs.results.filter(item => item.id.toString() === postData.program);
          if (programTitle) {
            if (programTitle[0].title === 'Llegar al Ser en 8 Semanas') {
              versionType = 'spanish';
            }
          }
          this.$router.push({ name: 'welcome', params: { version: versionType } });
        } else {
          this.formErrors = responseJson;
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  form {
    margin-bottom: 50px !important;
  }

  .form-group-program .custom-radio + .custom-radio {
    margin-top: 0.5rem;
  }

  .program-title {
    margin-bottom: 0;
    font-weight: bold;
  }

  /deep/ .discount_code-form-group {
    > div {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 1fr 6.6rem;
      align-items: start;
    }

    .btn {
      height: 38px;
    }
  }

  .total-cost {
    font-weight: bold;

    s {
      color: #ccc;
    }

    span {
      margin-left: 0.25rem;
      color: var(--success);
    }
  }

  .StripeElement {
    margin-bottom: 1rem;
  }
</style>
